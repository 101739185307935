
import {
    defineComponent
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { propertyBaseRouter, propertySubRouter } from '@/router';

interface MessageType {
    Title: string;
    Type: string;
    NickNames: string;
    Content: string;
    CreateTime: string;
}
export default defineComponent({
    components: {
        propertyBreadCrumb
    },
    setup() {
        const msgContent: Array<{
            title: string;
            text: string;
            name: keyof MessageType;
        }> = [{
            title: WordList.MessageListTanleTitle,
            text: '',
            name: 'Title'
        }, {
            title: WordList.TabelMessageBoxMessage,
            text: '',
            name: 'Content'
        }, {
            title: WordList.TabelMessageBoxReceiver,
            text: '',
            name: 'NickNames'
        }, {
            title: WordList.ProperAllTextTypeOfReceiver,
            text: '',
            name: 'Type'
        }, {
            title: WordList.TabelMessageBoxCreateTime,
            text: '',
            name: 'CreateTime'
        }];
        const breadHeaders = [{
            label: WordList.ProperAllTextGroupMessages,
            path: `/${propertyBaseRouter}/${propertySubRouter.message}`
        }, {
            label: WordList.TabelMessageBoxTitleDetail
        }];
        const messageInfo: MessageType = JSON.parse(localStorage.getItem('messageInfo')!);
        msgContent.forEach((item, index) => {
            msgContent[index].text = messageInfo[item.name];
        });
        return {
            breadHeaders,
            msgContent
        };
    }
});
