import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "shell info-container info-padding extra-padding" }
const _hoisted_2 = { class: "info-label-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_property_bread_crumb = _resolveComponent("property-bread-crumb")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_property_bread_crumb, { headers: _ctx.breadHeaders }, null, 8, ["headers"]),
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.msgContent, (item, index) => {
        return (_openBlock(), _createBlock("div", {
          class: ['info-item', index != _ctx.msgContent.length - 1?'item-bottom':''],
          key: index
        }, [
          _createVNode("p", _hoisted_2, _toDisplayString(item.title), 1),
          _createVNode("p", {
            class: [(item.name ==  'Message' || item.name ==  'NickNames')? 'message-background':'info-label-content','margin-top25px']
          }, _toDisplayString(item.text), 3)
        ], 2))
      }), 128))
    ])
  ]))
}